import React, { useState, useEffect } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { python } from '@codemirror/lang-python';
import { php } from '@codemirror/lang-php';
import '../assets/CodeEditor.css';
import { dracula } from '@uiw/codemirror-theme-dracula';

const CodeEditor = ({ language, onChange }) => {
    const [code, setCode] = useState(''); // Добавляем состояние для кода

    const extensions = {
        javascript: javascript(),
        python: python(),
        php: php(),
    };

    useEffect(() => {
        // Можно сбрасывать код при смене языка, если это нужно
        setCode(''); 
    }, [language]);

    const getExtension = (lang) => {
        return extensions[lang] || extensions['javascript'];
    };

    const handleCodeChange = (value) => {
        setCode(value);
        onChange(value);
    };

    return (
        <div className="code-editor card p-3">
            <h2>Ваш код</h2>
            <CodeMirror
                value={code}
                height="550px"
                theme={dracula}
                extensions={[getExtension(language)]}
                onChange={handleCodeChange}
            />
        </div>
    );
};

export default CodeEditor;