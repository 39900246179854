import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const Settings = ({ open, onClose, onSave, initialSettings }) => {
    const [settings, setSettings] = useState(initialSettings);

    useEffect(() => {
        setSettings(initialSettings);
    }, [initialSettings]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSave = () => {
        onSave(settings);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            disableEscapeKeyDown
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={modalStyle}>
                <h2 id="modal-title">Настройки</h2>
                <form noValidate autoComplete="off">
                    <TextField
                        select
                        fullWidth
                        label="Язык программирования"
                        name="CodingLang"
                        value={settings.CodingLang}
                        onChange={handleChange}
                        required
                        margin="normal"
                    >
                        <MenuItem value="php">PHP</MenuItem>
                        <MenuItem value="javascript">JavaScript</MenuItem>
                        <MenuItem value="python">Python</MenuItem>
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        label="Язык задач"
                        name="Language"
                        value={settings.Language}
                        onChange={handleChange}
                        required
                        margin="normal"
                    >
                        <MenuItem value="ru">Russian</MenuItem>
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="fr">French</MenuItem>
                        <MenuItem value="de">German</MenuItem>
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        label="Уровень слжности"
                        name="Level"
                        value={settings.Level}
                        onChange={handleChange}
                        required
                        margin="normal"
                    >
                        <MenuItem value="easy">Easy</MenuItem>
                        <MenuItem value="normal">Normal</MenuItem>
                        <MenuItem value="hard">Hard</MenuItem>
                    </TextField>
                    <TextField
                        fullWidth
                        label="Какие направления тренировать?"
                        name="Extended"
                        value={settings.Extended}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <label>
                        <input
                            type="checkbox"
                            name="UseTips"
                            checked={settings.UseTips}
                            onChange={handleChange}
                        />
                        Выводить подсказки ?
                    </label>
                </form>
                <Button variant="contained" color="primary" onClick={handleSave} className="mt-3">Save</Button>
            </Box>
        </Modal>
    );
};

export default Settings;
