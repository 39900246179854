// src/components/History.js
import React from 'react';
import '../assets/History.css';

const History = ({ tasks }) => {
    return (
        <div className="history card p-3 mb-3">
            <h2>История решенных задач</h2>
            <ul>
                {tasks.map((task, index) => (
                    <li key={index}>
                        <a href={`?task=${task.Id}`}>{task.Title}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default History;
