// src/components/Preloader.js
import React from 'react';
import '../assets/Preloader.css';

const Preloader = () => {
    return (
        <div className="preloader">
            <div className="spinner"></div>
        </div>
    );
};

export default Preloader;
