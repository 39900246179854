// src/components/Comment.js
import React from 'react';
import MarkdownRenderer from './MarkdownRenderer';
import '../assets/Comment.css';

const Comment = ({ text }) => {
    return (
        <div className="comment mt-3 p-2 border rounded">
            <h2>Комментарий к решению</h2>
            <MarkdownRenderer markdown={text} />
        </div>
    );
};

export default Comment;
