// src/components/TaskDescription.js
import React from 'react';
import MarkdownRenderer from './MarkdownRenderer';
import '../assets/TaskDescription.css';

const TaskDescription = ({ description, title }) => {
    return (
        <div className="task-description card p-3 mb-3">
            <h2>Описание задачи</h2>
            <h4>{title}</h4>
            <MarkdownRenderer markdown={description} />
        </div>
    );
};

export default TaskDescription;
