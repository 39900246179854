// src/components/SuccessMessage.js
import React from 'react';
import '../assets/SuccessMessage.css';

const SuccessMessage = ({ message }) => {
    return (
        <div className="success-message">
            {message}
        </div>
    );
};

export default SuccessMessage;
