import React, { useState, useEffect } from 'react';
import TaskDescription from './components/TaskDescription';
import CodeEditor from './components/CodeEditor';
import Hints from './components/Hints';
import Settings from './components/Settings';
import Comment from './components/Comment';
import SuccessMessage from './components/SuccessMessage';
import Preloader from './components/Preloader';
import History from './components/History';
import './assets/App.css';
import { fetchSession, fetchTask, validateTask } from './services/api';

const App = () => {
  const [code, setCode] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(JSON.parse(localStorage.getItem('settings')) || {
    CodingLang: '',
    Language: '',
    UseTips: false,
    Level: '',
    Extended: ''
  });
  const [task, setTask] = useState(JSON.parse(localStorage.getItem('task')) || {
    Id: null,
    Title: '',
    Exercise: '',
    CodingLang: '',
    Language: '',
    Level: '',
    Tips: ''
  });
  const [comment, setComment] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [history, setHistory] = useState(JSON.parse(localStorage.getItem('history')) || []);

  useEffect(() => {
    const handleOpenSettingsModal = () => setOpen(true);
    document.addEventListener('openSettingsModal', handleOpenSettingsModal);

    return () => {
      document.removeEventListener('openSettingsModal', handleOpenSettingsModal);
    };
  }, []);

  const clearCode = () => {
      setCode(''); // Очищаем код
  };

  const taskDescription = task.Exercise || "No task loaded";
  const taskTitle = task.Title || "No task loaded";
  const hints = settings.UseTips ? task.Hint : "";
  const language = task.CodingLang ? task.CodingLang.toLowerCase() : 'javascript'; // Default to javascript

  const handleSubmit = async () => {
    setComment(''); // Clear comment before submitting
    const solution = {
      TaskId: task.Id,
      Code: code
    };
    setLoading(true);
    const result = await validateTask(solution);
    setLoading(false);
    if (result.Validation) {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000); // Hide after 2 seconds
      // Update history
      const newHistory = [...history, task];
      setHistory(newHistory);
      localStorage.setItem('history', JSON.stringify(newHistory));
    } else {
      alert("Решение не верное!");
    }
    if (result.Comment) {
      setComment(result.Comment);
    }
  };

  const handleNextTask = async () => {
    setComment(''); // Clear comment before submitting
    setLoading(true);
    const sessionId = localStorage.getItem('sessionId');
    if (sessionId) {
      const newTask = await fetchTask(sessionId);
      localStorage.setItem('task', JSON.stringify(newTask));
      setTask(newTask);
      clearCode();
      // Update URL with new Task Id
      window.history.pushState(null, '', `?task=${newTask.Id}`);
    }
    setLoading(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSaveSettings = async (newSettings) => {
    const userId = '369069'; // Extract this from JWT if available
    try {
      setLoading(true);
      const savedSettings = await fetchSession({ ...newSettings, UserId: userId });
      setLoading(false);
      localStorage.setItem('settings', JSON.stringify(savedSettings));
      setSettings(savedSettings);
      handleClose();
      handleNextTask();
    } catch (error) {
      setLoading(false);
      console.error('Failed to save settings:', error);
    }
  };

  return (
    <div className="app-container container-fluid d-flex p-3">
      {loading && <Preloader />}
      {showSuccess && <SuccessMessage message="Ура! Задача решена корректно!" />}
      <div className="task-section col-12 col-md-4">
        <TaskDescription description={taskDescription} title={taskTitle} />
        <Hints hints={hints} />
        <History tasks={history} />
      </div>
      <div className="editor-section col-12 col-md-8 d-flex flex-column">
        <CodeEditor language={language} onChange={setCode} />
        <div className="d-flex justify-content-between mt-3">
          <button className="submit-button btn btn-success" onClick={handleSubmit}>Проверить решение</button>
          <div className="mt-3">
            <button className="next-task-button btn btn-primary me-2" onClick={handleNextTask}>Новая задача</button>
            <button className="settings-button btn btn-secondary" onClick={handleOpen}>Настройки</button>
          </div>
        </div>
        {settings.UseTips && comment && <Comment text={comment} />}
      </div>

      <Settings open={open} onClose={handleClose} onSave={handleSaveSettings} initialSettings={settings} />
    </div>
  );
};

export default App;
