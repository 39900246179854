import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchJWT, validateJWT, getSession, fetchTask, getTaskById } from './services/api';

const initializeApp = async () => {
  let token = localStorage.getItem('jwtToken');
  if (!token || !(await validateJWT(token))) {
    await fetchJWT();
    token = localStorage.getItem('jwtToken');
  }

  const urlParams = new URLSearchParams(window.location.search);
  const taskId = urlParams.get('task');

  if (taskId) {
    const data = await getTaskById(taskId);
    localStorage.setItem('task', JSON.stringify(data.Task));
    localStorage.setItem('sessionId', data.Session.Id);
    const settings = await getSession(data.Session.Id);
    return { settings, task: data.Task };
  }

  let sessionId = localStorage.getItem('sessionId');
  if (!sessionId) {
    return { settings: null, task: null };
  } else {
    const settings = await getSession(sessionId);
    const task = await fetchTask(sessionId);
    return { settings, task };
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = () => {
  useEffect(() => {
    const init = async () => {
      const { settings, task } = await initializeApp();
      if (!settings) {
        // Show settings modal
        document.dispatchEvent(new Event('openSettingsModal'));
      } else {
        // Initialize settings
        localStorage.setItem('settings', JSON.stringify(settings));
      }
      if (task) {
        localStorage.setItem('task', JSON.stringify(task));
        // Update URL with Task Id if not already set
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.get('task')) {
          window.history.pushState(null, '', `?task=${task.Id}`);
        }
      }
    };
    init();
  }, []);

  return <App />;
};

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);

reportWebVitals();
