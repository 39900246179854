// src/services/api.js
import config from '../config';

export const fetchJWT = async () => {
    const response = await fetch(`${config.backendUrl}/create-jwt`, {
        method: 'POST',
    });
    const data = await response.json();
    localStorage.setItem('jwtToken', data.User.Token);
};

export const validateJWT = async (token) => {
    const response = await fetch(`${config.backendUrl}/validate-jwt`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    const data = await response.json();
    return data.Validate;
};

export const fetchSession = async (settings) => {
    const token = localStorage.getItem('jwtToken');
    const response = await fetch(`${config.backendUrl}/create-session`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(settings)
    });
    const data = await response.json();
    localStorage.setItem('sessionId', data.Session.Id);
    return data.Session.Settings;
};

export const getSession = async (sessionId) => {
    const token = localStorage.getItem('jwtToken');
    const response = await fetch(`${config.backendUrl}/get-session/${sessionId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    const data = await response.json();
    return data.Session.Settings;
};

export const fetchTask = async (sessionId) => {
    const token = localStorage.getItem('jwtToken');
    const response = await fetch(`${config.backendUrl}/get-task`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ SessionId: sessionId })
    });
    const data = await response.json();
    return data.Task;
};

export const getTaskById = async (taskId) => {
    const token = localStorage.getItem('jwtToken');
    const response = await fetch(`${config.backendUrl}/get-task/${taskId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    const data = await response.json();
    return data;
};

export const validateTask = async (solution) => {
    const token = localStorage.getItem('jwtToken');
    const response = await fetch(`${config.backendUrl}/validate-task`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(solution)
    });
    const data = await response.json();
    return data;
};
