// src/components/Hints.js
import React from 'react';
import MarkdownRenderer from './MarkdownRenderer';
import '../assets/Hints.css';

const Hints = ({ hints }) => {
    return (
        <div className="hints card p-3 mb-3">
            <h2>Подсказки</h2>
            <MarkdownRenderer markdown={hints} />
        </div>
    );
};

export default Hints;
